import React, { useEffect, useState } from 'react';
import BasicModal from '../components/BasicModal';
import MarketingHead from '../components/marketing-meta';
import MarketingFooter from '../components/MarketingFooter';
import Navbar from '../components/NavBar';

const TrackingPage = () => {
    const [salesModal, setSalesModal] = useState(false);


    // load the HS script
    useEffect(() => {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
  
      })
  
    }, []);
  
    // populate a lil form
    useEffect(() => {
      if(window.hbspt && salesModal) {
        window.hbspt.forms.create({
          portalId: '14557468',
          formId: '2be2ed45-2b95-4eb4-9301-40856c8cb10d',
          target: '#hubspotForm'
        })
      }
    }, [salesModal])

    return (
        <>
            <title>Kodama - Carbon Broker Solutions</title>
            <MarketingHead />
            <BasicModal title="Join the brokerage beta!" isOpen={salesModal} close={() => setSalesModal(false)}>
                Interested in selling carbon credits through Kodama?
                <div id="hubspotForm" className="mt-2"/>
            </BasicModal>
            <div className={'bg-hero-image bg-cover p-1 text-white'}>
                <Navbar /> 
                <div className="container xl:px-40 mt-10 mb-8 mx-auto">
                    <div className="min-h-96 p-2 w-full my-12">
                        <p className="text-5xl mb-8 font-bold">Kodama for Carbon credit Brokerages</p>
                        {/* <div className="px-4 mt-6 mx-2 border-l w-2/3 border-white"> */}
                        <p className="mt-2 text-lg">Itegrating and selling carbon offsets with Kodama will give your brokerage real-time tracking and insights into your customer's behaivior</p>
                        <p className="mt-2 text-lg">Give your customers a new set of tools to manage their offsets, increasing engagement.</p>
                        <p className="mt-2 text-lg">Understand exactly how much carbon you're emitting for each product you sell.</p>
                        {/* </div> */}
                        <button onClick={() => setSalesModal(true)} className="border border-white rounded-md text-white px-4 py-2 mt-4">
                        Join the brokerage beta
                        </button>
                    </div>          
                </div> 
            </div>

            <div className="container xl:px-40 mt-24 mb-5 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 items-center  px-4 lg:px-0">
                <div>
                    <h1 className="text-5xl font-bold text-primary">Build your brokerage with Kodama</h1>
                </div>
                <div className="border-l border-primary pl-4">
                    <p className="text-lg mt-2 text-gray-700">
                        Don't build your own digital carbon brokerage, sell through Kodama. Add your verfied projects, set your prices, and sell to your customers 
                        without building in-house tools or solutions.
                    </p>
                </div>
            </div>
            
            <div className="container xl:px-40 mt-32 mb-5 px-4 lg:px-0 lg:mx-auto">
               <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 bg-gray-50 shadow-lg border border-gray-200 p-8 rounded-lg">
                   <div className="mb-4 lg:mb-0">
                       <div className="inline-block">
                        <div className="bg-green-100 text-primary rounded-full w-10 h-10 flex items-center justify-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold text-primary">Sell offsets, process payments</h2>
                       </div>
                       <p className="mt-4 leading-8">Kodama can handle the payment infrastructure for selling your offsets. Don't fret over credit card processing, or integrating with your customers products.</p>
                   </div>
                   <div className="mb-4 lg:mb-0">
                       <div className="inline-block">
                        <div className="bg-green-100 text-primary rounded-full w-10 h-10 flex items-center justify-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold text-primary">Give your customers integration support</h2>
                       </div>
                       <p className="mt-4 leading-8">
                            Kodama provides API integration support so your customers can better leverage the data surrounding their carbon offsets, purchase additional offset,
                            and track their emissions. Give your brokerage customers the tools they need to integrate carbon offsets into their product.
                        </p>
                   </div>
                   <div className="mb-4 lg:mb-0">
                       <div className="inline-block">
                        <div className="bg-green-100 text-primary rounded-full w-10 h-10 flex items-center justify-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold text-primary">Real time data for you and your customers</h2>
                       </div>
                       <p className="mt-4 leading-8">
                           Show your customers and stakeholders realtime data about how customers are leveraging carbon offsets. Gain insights into customers GHG emissions in real time.
                           Make more informed desicions with better customer data. 
                       </p>
                   </div>
               </div>
            </div>
      <MarketingFooter />
      </>
    )
}

export default TrackingPage;
